<div class="sphere-app-container" #appContainer [class.free]="user && !user.active_subscription">
  <div
    class="fs-header-background"
    *ngIf="appService.fullscreenHeader"
    [ngStyle]="{ 'background-image': 'url(' + appService.fullscreenHeader.image + ')', height: appService.fullscreenHeader.height, opacity: appService.fullscreenHeader.opacity || 0.6 }"
  ></div>
  <div *ngIf="helperService.token && user && !userService.isInWelcomeFlow && showHeader">
    <nav><myp-cloud-nav></myp-cloud-nav></nav>
  </div>
  <div id="sphere-app-content" class="sphere-app-content" [class.header-hidden]="!showHeader" (scroll)="appService.sphereAppContentScrolled$.next($event.target.scrollTop)">
    <router-outlet></router-outlet>
  </div>
  <myp-image-viewer></myp-image-viewer>
  <myp-video-player></myp-video-player>
  <myp-action></myp-action>
  <myp-notification></myp-notification>
  <myp-audio-player></myp-audio-player>
  <ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
</div>
